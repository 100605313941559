@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
  min-height: 100vh;
  position: relative;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.shadow-text {
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

body::before,
body::after {
  content: "";
  position: absolute;
  width: 300px;
  height: 300px;
  z-index: -1;
}

body::before {
  top: -150px;
  left: -150px;
  background: radial-gradient(
    circle,
    rgba(255, 255, 255, 0.2) 0%,
    rgba(255, 255, 255, 0) 70%
  );
}

body::after {
  bottom: -150px;
  right: -150px;
  background: radial-gradient(
    circle,
    rgba(173, 216, 230, 0.2) 0%,
    rgba(173, 216, 230, 0) 70%
  );
}

.content-wrapper {
  background-color: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(10px);
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin: 2rem auto;
  max-width: 95%;
  padding: 2rem;
}

@media (max-width: 768px) {
  .content-wrapper {
    margin: 1rem;
    padding: 1rem;
  }
}

/* El resto de tus estilos permanecen iguales */
